//assets
import { ReactComponent as Handshake } from "../assets/icons/icon-handshake.svg";
import banner from "../assets/images/leafBannerSmaller.jpg";
import Guarantee from "../components/guarantee";
//components
import Layout from "../components/layout";
import SEO from "../components/seo";
import Service from "../components/service";
import StyledButton from "../components/styledButton";
import { graphql, Link } from "gatsby";
import React from "react";

function Hospice({ data }) {
  const services = data.services.edges;

  return (
    <Layout hasHero={false} heroSize="30rem" heroImage={banner}>
      <SEO
        title="Easing the Process of Death in Hospice Care | City of Oaks Funeral Home & Cremation in Raleigh, NC"
        description="City of Oaks Funeral Home in Raleigh helps ease the process of end of life arrangements for hospice families."
      />
      <div className="flex flex-col mt-10 md:mt-16">
        <div className="w-16 h-16 mb-4 self-center">
          <Handshake />
        </div>

        <div className="font-serif font-hairline">
          <h2 className="text-3xl md:text-4xl text-green-700">
            Easing the Process of End-of-Life Arrangements
          </h2>
        </div>
        <div className="flex flex-col mt-10 md:mt-16">
          <p className="py-4">
            At City of Oaks Cremation, we understand the emotional and practical
            challenges that come with losing a loved one. Our mission is to
            simplify the process of end-of-life arrangements, providing you with
            practical and affordable solutions during this difficult time. We
            help families every day with cremation, burial, traditional funerals
            and countless other options.
          </p>
          <h3 className="my-2 text-2xl text-gray-800">
            What to Do When Your Loved One Passes in Hospice
          </h3>
          <p className="py-2">
            When your loved one passes, call the hospice facility or your
            hospice nurse directly. They will confirm and pronounce the death,
            and perform any necessary aftercare activities. Your hospice nurse
            can then <a href="tel:9194381649">contact us at (919) 438-1649 </a>
            to arrange for our team to bring your loved one into our care.
          </p>
          <h3 className="my-2 text-2xl text-gray-800">Helpful Suggestions</h3>
          <p className="py-2">
            <ul>
              <li>
                Notify immediate family members and enlist their help in
                notifying others.
              </li>
              <li>
                Consider reaching out to us in advance to discuss plans for
                cremation, burial, or anatomical donation.
              </li>
              <li>
                Implement a Healthcare Power of Attorney Document to ensure you
                have the legal authority to make final arrangements.
              </li>
            </ul>
          </p>
          <h3 className="my-2 text-2xl text-gray-800">We're Here for You</h3>
          <p className="py-2">
            Dealing with the loss of a loved one is never easy. Our funeral
            directors are here to answer any questions you may have and provide
            guidance throughout the process. We believe in practical, affordable
            solutions that ease the burden on you and your family.
          </p>
          <p className="py-2">
            If you have any questions or would like to discuss your options,
            please don't hesitate to reach out to us. We're here to help.
          </p>
          <div className="self-center my-4">
            <Link to="/contact/">
              <StyledButton>Talk to us</StyledButton>
            </Link>
          </div>
          <h3 className="my-2 text-2xl text-gray-800">
            Guide to Hospice End of Life Arrangements
          </h3>
          <p className="py-2">
            Read the rest of our guide to hospice care and end-of-life
            arrangements <Link to="/hospice">here</Link>.
          </p>
        </div>
        <Guarantee />

        {/* services block here */}
        <p className="mb-2 text-4xl text-gray-800 self-center">Our Services</p>
        <div className="flex flex-wrap justify-center items-stretch -mx-2">
          {services.map(({ node }) => (
            <Service
              title={node.frontmatter.title}
              slug={node.frontmatter.slug}
              url={node.frontmatter.path}
              key={node.frontmatter.path}
              icon={node.frontmatter.icon}
              price={node.frontmatter.price}
              blurb={node.frontmatter.blurb}
            ></Service>
          ))}
        </div>

        <div className="self-center my-3">
          <Link to="/contact/">
            <StyledButton>Find out more</StyledButton>
          </Link>
        </div>
      </div>
      <div className="w-full mt-6"></div>

      <div className="flex flex-col mt-10 md:mt-16">
        <p className="mb-2 text-3xl text-green-700 self-center pb-4 font-serif">
          City of Oaks is committed to providing families with unsurpassed and
          professional service before, during, and after the time of loss.
        </p>
        <p className="py-3">
          Our Funeral Home is located in Wake County, North Carolina; however,
          we provide our services to families throughout the entire state.
          Regardless of what County you live in, we can help your family with
          making simple, affordable funeral arrangements.
        </p>
        <p className="py-3">
          We have an all-inclusive, low cost approach. The families we serve are
          typically educated, practical, environmentally conscious and
          economically sound decision makers. More frequently, families are
          deciding to bypass the lavish floral displays, catered receptions,
          expensive hearses and family cars or limousines that have often been
          associated with traditional American funerals of the past.
        </p>
        <p className="py-3">
          We provide each family with a Licensed Funeral Director who will work
          with you from start to finish. Our team takes pride in being able to
          assist families with a wide range of cultures, religions, economic and
          other influencing factors. We provide compassionate care and
          understanding to families at the time of a pending death by making
          sure they are informed of what steps will follow and how we will be
          there to assist them through the entire process. Our Funeral Directors
          provide the reassuring voice that families rely on while going through
          a difficult time when a loved one passes away.
        </p>
        <div className="self-center my-3">
          <Link to="/services/">
            <StyledButton>Our services</StyledButton>
          </Link>
        </div>
      </div>
    </Layout>
  );
}

export const query = graphql`
  query {
    services: allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "/content/services/" } }
    ) {
      edges {
        node {
          frontmatter {
            title
            slug
            path
            icon
            price
            blurb
          }
          excerpt
        }
      }
    }
  }
`;

export default Hospice;
